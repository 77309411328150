import { useStaticQuery, graphql } from 'gatsby'

const useNewsQuery = () => {
  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery(graphql`
    query NewsQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        nodes {
          html
          id
          excerpt(pruneLength: 400)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MM YYYY")
            featuredimage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return nodes
}

export default useNewsQuery
